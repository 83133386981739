import { Box, Typography } from '@mui/material';
import Tile from 'features/layout/tiles/Tile';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const GridPlaceholder = () => {
  const { t } = useTranslation('room');
  return (
    <Tile cols={1}>
      <Box sx={{ padding: '1rem', textAlign: 'center' }}>
        <Typography variant="body2" color="white">
          {t('grid.no_tiles_placeholder')}
        </Typography>
      </Box>
    </Tile>
  );
};
