import { screenshareSelected } from 'features/streaming/actions';
import {
  selectedScreenshareChanged,
  selectSelectedScreenshare,
} from 'features/streaming/streamingSlice';
import { SelectedScreenshare } from 'features/streaming/types';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { batchToggleMediaTemplate, sendMessage } from 'utils/webrtc/messages';

export function* onScreenshareSelectedSaga(action: ReturnType<typeof screenshareSelected>) {
  const { userId, feedId } = action.payload;

  const selectedScreenshare: SelectedScreenshare | null = yield select(selectSelectedScreenshare);

  if (userId === selectedScreenshare?.userId) {
    return;
  }
  const feed = RTCClient.receivingFeed.receiver?.plugin?.janusPlugin;

  if (selectedScreenshare) {
    const { streams } = RTCClient.receivingFeed.media.getFeedStreams(selectedScreenshare.feedId);

    const request = Object.values(streams).map((stream) => ({
      mid: stream.subscriberMid || '-1',
      send: false,
    }));

    if (feed) {
      yield call(sendMessage, feed, batchToggleMediaTemplate(request));
    }
  }

  if (RTCClient.receivingFeed.subscribedScreenshares[feedId]) {
    const { streams } = RTCClient.receivingFeed.media.getFeedStreams(feedId);

    const request = Object.values(streams).map((stream) => ({
      mid: stream.subscriberMid || '-1',
      send: true,
    }));

    if (feed) {
      yield call(sendMessage, feed, batchToggleMediaTemplate(request));
    }
  } else {
    const streams = RTCClient.receivingFeed.availableScreenshares[feedId];

    if (streams?.length) {
      RTCClient.receivingFeed.subscribedScreenshares[feedId] = true;

      yield call(
        RTCClient.subscribe,
        {
          feedId,
          streams,
          id: userId,
        },
        true
      );
    }
  }

  yield put(selectedScreenshareChanged({ userId, feedId }));
}
