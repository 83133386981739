import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import { screenshareAdded } from 'features/layout/features/order/orderSlice';
import { put } from 'redux-saga/effects';
import { SignalingRoomUser } from 'services/signaling';
import { RTCClient } from 'utils/webrtc';

export function* prepareExposedContentSaga(users: SignalingRoomUser[], localUserId: string) {
  const usersWithScreenshare = users
    .filter(
      (user) => user.screensharing && !!user.screenshareFeedId && user.screenshareStreams?.length
    )
    // pick up the most recent screenshare
    .sort((userA, userB) => {
      if (userA.screenshareStartedTimestamp && userB.screenshareStartedTimestamp) {
        return userB.screenshareStartedTimestamp - userA.screenshareStartedTimestamp;
      }

      return 0;
    });

  if (!usersWithScreenshare.length) {
    return null;
  }

  for (const user of usersWithScreenshare) {
    if (user.screenshareStreams) {
      RTCClient.receivingFeed.availableScreenshares[user.feedId] = user.screenshareStreams;
    }

    yield put(
      screenshareAdded({
        kind: `screenshare-${user.id === localUserId ? 'local' : 'remote'}`,
        userId: user.id,
        feedId: user.feedId,
      })
    );
  }

  yield put(gridPanelOpened('screenshare'));
}
