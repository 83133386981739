import { Box, ListItemText, MenuItem } from '@mui/material';
import Icon from 'components/Icon';
import Menu from 'components/Menu/Menu';
import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { screenshareSelected } from 'features/streaming/actions';
import { Chip } from 'features/streaming/components/stream-display/StreamBadge';
import StreamDisplay from 'features/streaming/components/stream-display/StreamDisplay';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ScreensharePanel = () => {
  const dispatch = useAppDispatch();
  const [activeScreenshare, setActiveScreenshare] = useState<number | undefined>(undefined);
  const screenshares = useAppSelector(selectScreenshareStreams);
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);

  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (!screenshares.length) {
      dispatch(gridPanelVoided('screenshare'));
    }

    setActiveScreenshare(screenshares.length - 1);
  }, [screenshares, dispatch]);

  const activeScreenshareEntry =
    activeScreenshare !== undefined ? screenshares[activeScreenshare] : null;

  const selectScreenshare = (idx: number) => {
    const screenshare = screenshares[idx];
    if (screenshare?.feedId) {
      dispatch(screenshareSelected({ feedId: screenshare.feedId, userId: screenshare.userId }));

      setActiveScreenshare(idx);
    }
  };

  return screenshares.length && activeScreenshareEntry ? (
    <GridPanelContainer id="screenshare-container">
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          width: '100%',
          top: (theme) => theme.spacing(2),
          textAlign: isMobile ? 'left' : 'center',
          padding: (theme) => theme.spacing(0, 2),
          zIndex: 3,
        }}
      >
        <Menu
          id="screenshare-selector"
          open={menuOpen}
          onClose={closeMenu}
          placement={isMobile ? 'bottom-start' : 'bottom'}
          trigger={
            <button
              type="button"
              className="button-appearance-none"
              onClick={() => {
                if (screenshares.length > 1) {
                  setMenuOpen((open) => !open);
                } else {
                  setMenuOpen(false);
                }
              }}
            >
              <Chip
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{`Screenshare (${activeScreenshareEntry.kind === 'screenshare-local' ? 'You' : activeScreenshareEntry.user?.name})`}</span>
                    {screenshares.length > 1 ? (
                      <Icon
                        name="dropdown-arrow"
                        sx={{
                          fontSize: '32px',
                          marginRight: '-10px',
                          transform: 'translateY(-2px)',
                        }}
                      />
                    ) : null}
                  </div>
                }
              />
            </button>
          }
          PaperProps={{
            sx: (theme) => ({
              backgroundColor: theme.palette.grey['900'],
            }),
          }}
          sx={(theme) => ({
            color: theme.palette.common.white,
          })}
        >
          {screenshares.map((screenshare, idx) =>
            screenshare ? (
              <MenuItem key={screenshare.userId} onClick={() => selectScreenshare(idx)}>
                <ListItemText>
                  Screenshare (
                  {screenshare.kind === 'screenshare-local' ? 'You' : screenshare.user?.name})
                </ListItemText>
              </MenuItem>
            ) : null
          )}
        </Menu>
      </Box>

      <StreamDisplay source={activeScreenshareEntry} maximized />
    </GridPanelContainer>
  ) : null;
};
