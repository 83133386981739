import { createSelector, createSlice, PayloadAction, prepareAutoBatched } from '@reduxjs/toolkit';
import { LayoutOrderState } from 'features/layout/features/order/types';
import { SourceDetails } from 'features/layout/types';
import { sourceMatches } from 'features/streaming/utils';
import { selectUsers } from 'features/users/usersSlice';
import { RootState } from 'store/store';

export const initialState: LayoutOrderState = {
  orderedStreams: [],
  screenshareStreams: [],
  historicalSpeakers: [],
};

const layoutOrderSlice = createSlice({
  name: 'layoutOrder',
  initialState,
  reducers: {
    orderUpdated: {
      reducer(state, action: PayloadAction<SourceDetails[]>) {
        state.orderedStreams = action.payload;
      },
      prepare: prepareAutoBatched<SourceDetails[]>(),
    },
    orderedStreamAdded(state, action: PayloadAction<SourceDetails>) {
      state.orderedStreams.push(action.payload);
    },
    orderedStreamDeleted(state, action: PayloadAction<SourceDetails>) {
      state.orderedStreams = state.orderedStreams.filter(
        (stream) => !sourceMatches(stream, action.payload)
      );
    },
    screenshareAdded(state, action: PayloadAction<SourceDetails>) {
      state.screenshareStreams = state.screenshareStreams.filter(
        (stream) => stream.userId !== action.payload.userId
      );

      state.screenshareStreams.push(action.payload);
    },
    screenshareDeleted(state, action: PayloadAction<SourceDetails>) {
      state.screenshareStreams = state.screenshareStreams.filter(
        (stream) => !sourceMatches(stream, action.payload)
      );
    },
    speakersUpdated: {
      reducer(state, action: PayloadAction<SourceDetails[]>) {
        state.historicalSpeakers = action.payload;
      },
      prepare: prepareAutoBatched<SourceDetails[]>(),
    },
  },
});

export const {
  orderUpdated,
  orderedStreamAdded,
  orderedStreamDeleted,
  speakersUpdated,
  screenshareDeleted,
  screenshareAdded,
} = layoutOrderSlice.actions;

export const selectOrderedStreams = (state: RootState) => state.layout.order.orderedStreams;
export const selectScreenshareStreams = createSelector(
  [selectUsers, (state: RootState) => state.layout.order.screenshareStreams],
  (users, screenshares) =>
    screenshares
      .map((screenshare) =>
        users[screenshare.userId]
          ? {
              ...screenshare,
              user: users[screenshare.userId],
            }
          : undefined
      )
      .filter(Boolean)
);

export const selectHistoricalSpeakers = (state: RootState) => state.layout.order.historicalSpeakers;

export const selectActiveSpeaker = (state: RootState) =>
  state.layout.order.orderedStreams.find(
    (source) => source.userId === state.streaming.activeSpeakerId
  );

export const selectActiveSpeakerId = (state: RootState) => state.streaming.activeSpeakerId;

export default layoutOrderSlice.reducer;
