import { GridPanelControlsContainer } from 'features/layout/GridPanel/GridPanelControls';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { useAppDispatch } from 'store/hooks';
import { fileThumbnailsToggled } from 'features/content-library/contentLibrarySlice';

export const ToggleThumbnailsControl = () => {
  const dispatch = useAppDispatch();

  const toggleThumbnails = () => {
    dispatch(fileThumbnailsToggled());
  };

  return (
    <GridPanelControlsContainer sx={{ top: '5px', left: '0px' }} className="hover-child">
      <GridPanelControl icon="thumbnail-panel" onClick={toggleThumbnails} />
    </GridPanelControlsContainer>
  );
};
