import { call, select } from 'redux-saga/effects';
import { CloseCodes, SignalingSocket } from 'services/signaling';
import { RTCClient } from 'utils/webrtc';
import { SFrameManager } from 'features/e2ee/SFrameManager';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';

export function* destroySessionSaga() {
  yield call(SignalingSocket.close, CloseCodes.TERMINATED);
  yield call(RTCClient.destroy);

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    yield call(SFrameManager.cleanup);
    yield call(E2EEManager.cleanup);
  }

  try {
    window.documentPictureInPicture?.window?.close();
  } catch {
    //   too bad
  }
}
