import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { useAppDispatch } from 'store/hooks';

export const useScreensharePanelCloseControl = () => {
  const dispatch = useAppDispatch();

  const { hasPermissions } = usePermissions();
  const canPresent = hasPermissions(PermissionTypes.presentContentLibrary);

  const controlEnabled = canPresent;

  const handleClick = () => {
    dispatch(gridPanelVoided('screenshare'));
  };

  return { controlEnabled, handleClick };
};

export const ScreensharePanelCloseControl = () => {
  const { controlEnabled, handleClick } = useScreensharePanelCloseControl();

  return controlEnabled ? <GridPanelControl icon="close" onClick={handleClick} /> : null;
};
