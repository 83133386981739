import { PayloadAction } from '@reduxjs/toolkit';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { screenshareAdded } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { screenshareSelected } from 'features/streaming/actions';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';
import { StreamingStarted } from '../types';

export function* onScreensharingStartSaga(action: PayloadAction<StreamingStarted>) {
  const { id: userId, feedId, streams } = action.payload;

  yield call(RTCClient.receivingFeed.addAvailableStreams, feedId, streams);
  RTCClient.receivingFeed.availableScreenshares[feedId] = streams;

  if (!RTCClient.localFeeds[feedId]) {
    RTCClient.screensharingFeed.pendingReconnect = false;

    yield put(screenshareSelected({ userId, feedId }));
  }

  yield call(RTCClient.enableScreensharingMedia, userId, streams);

  const source: SourceDetails = {
    userId: action.payload.id,
    feedId,
    kind: `screenshare-${RTCClient.localFeeds[feedId] ? 'local' : 'remote'}`,
  };

  yield put(screenshareAdded(source));

  // TODO;
  yield put(gridPanelOpened('screenshare'));

  const pinnedContentWidth: number = yield select(selectPinnedContentWidth);
  yield put(contentWidthChanged(pinnedContentWidth));
  yield call(eventBus.sendMessage, 'screenshareStarted', { userId });
}
