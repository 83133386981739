import { put } from 'redux-saga/effects';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import { fileOpened } from 'features/content-library/contentLibrarySlice';
import { signalingContentLibraryFileOpened } from 'features/content-library/actions';

export function* onContentLibraryFileOpenedSaga(
  action: ReturnType<typeof signalingContentLibraryFileOpened>
) {
  yield put(fileOpened(action.payload.data));
  yield put(gridPanelOpened('contentLibrary'));
}
