import { FeedId, PublisherMid, SubscriberMid } from 'features/streaming/types';
import { StreamMedia } from 'utils/webrtc/receiving/StreamMedia';
import { StreamKind } from 'utils/webrtc/types';

class FeedMediaList {
  private feedId: FeedId;

  streams: Record<PublisherMid, StreamMedia> = {};

  private midByKind: Partial<Record<StreamKind, PublisherMid>> = {};

  constructor(feedId: FeedId) {
    this.feedId = feedId;
  }

  getStream = (mid: PublisherMid) => {
    this.streams[mid] ??= new StreamMedia(this.feedId, mid);

    return this.streams[mid];
  };

  getVideoStream: () => StreamMedia | undefined = () => {
    if (this.midByKind.video) {
      return this.streams[this.midByKind.video];
    }

    return undefined;
  };

  getAudioStream: () => StreamMedia | undefined = () => {
    if (this.midByKind.audio) {
      return this.streams[this.midByKind.audio];
    }

    return undefined;
  };

  deleteStream = (mid: PublisherMid) => {
    if (this.streams[mid].kind) {
      delete this.midByKind[this.streams[mid].kind];
    }
    delete this.streams[mid];
  };

  setMidByKind = (kind: StreamKind, mid: PublisherMid) => {
    this.midByKind[kind] = mid;
  };

  forEachStream = (handler: (stream: StreamMedia, mid: PublisherMid, index: number) => void) => {
    Object.entries(this.streams).forEach(([mid, stream], index) => handler(stream, mid, index));
  };

  get hasStreams() {
    return !!Object.values(this.streams).length;
  }
}

export class MediaList {
  publisherMidBySubscriberMid: Record<SubscriberMid, PublisherMid> = {};

  streams: Record<FeedId, FeedMediaList> = {};

  subscribedFeeds: Record<FeedId, true> = {};

  getFeedStreams = (feedId: FeedId) => {
    this.streams[feedId] ??= new FeedMediaList(feedId);

    return this.streams[feedId];
  };

  getMediaList = (feedId: FeedId) => {
    const list = this.getFeedStreams(feedId);

    return Object.values(list.streams).map((stream) => ({
      type: stream.kind,
      mid: stream.publisherMid,
    }));
  };

  stream = (feedId: FeedId, mid: PublisherMid) => {
    const streams = this.getFeedStreams(feedId);

    return streams.getStream(mid);
  };

  subscriberStream = (feedId: FeedId, mid: SubscriberMid) => {
    const publisherMid = this.publisherMidBySubscriberMid[mid];

    if (typeof publisherMid !== 'undefined') {
      return this.stream(feedId, publisherMid);
    }

    return undefined;
  };

  freeStreamBySubscribedMid = (feedId: FeedId, mid: SubscriberMid) => {
    const publisherMid = this.publisherMidBySubscriberMid[mid];

    if (typeof publisherMid !== 'undefined') {
      const stream = this.stream(feedId, publisherMid);

      this.deleteStream(feedId, stream.publisherMid);
    }
  };

  setSubscriberMid = (feedId: FeedId, mid: PublisherMid, subscriberMid: SubscriberMid) => {
    const stream = this.stream(feedId, mid);

    this.publisherMidBySubscriberMid[subscriberMid] = mid;

    this.subscribedFeeds[feedId] = true;

    stream.setSubscriberMid(subscriberMid);
  };

  setKind = (feedId: FeedId, mid: PublisherMid, kind: StreamKind) => {
    const stream = this.stream(feedId, mid);
    const list = this.getFeedStreams(feedId);

    list.setMidByKind(kind, mid);

    stream.setKind(kind);
  };

  deleteFeed = (feedId: FeedId) => {
    delete this.streams[feedId];
    delete this.subscribedFeeds[feedId];
  };

  deleteStream = (feedId: FeedId, mid: PublisherMid) => {
    const list = this.getFeedStreams(feedId);

    list.deleteStream(mid);
    if (!list.hasStreams) {
      delete this.subscribedFeeds[feedId];
    }
  };

  get feedsWithVideo() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(this.streams).filter(([_, feed]) => feed.getVideoStream());
  }

  forEachFeed = (handler: (list: FeedMediaList, feedId: FeedId, index: number) => void) => {
    Object.entries(this.streams).forEach(([feedId, list], index) => handler(list, feedId, index));
  };

  forEachSubscribedFeed = (
    handler: (list: FeedMediaList, feedId: FeedId, index: number) => void
  ) => {
    Object.entries(this.streams).forEach(([feedId, list], index) => {
      if (this.subscribedFeeds[feedId]) {
        handler(list, feedId, index);
      }
    });
  };
}
